<template>
  <h3>{{name}}’s Settings</h3>
  <hr class="border-light my-3">
  <div class="row mb-3">
    <div class="col-auto mb-1">
      <button type="button" class="btn btn-danger" @click="displayDelAccModal=true">Delete Account</button>
    </div>
    <div class="col-auto mt-1">
      <span class="form-text">
        This will delete all your private information from our storage (your public information may still be used by other users).
      </span>
    </div>
  </div>
  <DelAccModal v-if="displayDelAccModal" @close="displayDelAccModal=false" @logout="logout"/>
</template>
<script type="text/javascript">
  import DelAccModal from "@/components/DelAccModal";
  import {mapState} from 'vuex';
  export default {
    data(){
      return {
        displayDelAccModal:false
      };
    },
    components:{
      DelAccModal
    },
    computed:{
      ...mapState(['name'])
    },
    methods:{
      logout() {
        this.$store.dispatch('logout','You have successfully deleted your account.');
      }
    }
  }
</script>
