<template>
  <transition name="modal">
    <div class="modal fade show" id="modalForm" tabindex="-1" @click.self="$emit('close')">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ site }} Account Deletion</h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="deleteAcc">
              <div class="alert alert-danger mb-3" role="alert" v-if="error">
                {{error}}
              </div>
              <div class="mb-3">
                <label class="form-label">Are you sure you wish to delete your account?</label>
              </div>
              <div class="mb-3">
                <span class="form-text">
                  This will delete all your private information from our storage (your public information may still be used by other users).
                </span>
              </div>
              <div class="modal-footer d-block">
                <div class="btn-group" role="group">
                  <button type="submit" class="btn btn-danger">Confirm</button>
                  <button type="submit" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="modal-backdrop fade show"></div>
</template>

<style lang="scss" scoped>
.modal {
  display:block;
}

/*
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
*/
</style>
<script type="text/javascript">
  import fetch from '@/fetch.js';
  import {mapState} from 'vuex';

  export default {
    data(){
      return{
        error:null,
      }
    },
    computed:{
      ...mapState(['site'])
    },
    methods:{
      async deleteAcc() {
        this.error = null;
        const result = await fetch('/api/v1/account',{
          method:'delete'
        });
        if ('error' in result)
          this.error = result.error;
        else {
          this.$emit('logout',result);
        }
      },
    },
    emits: ['close','logout']
  }
</script>
